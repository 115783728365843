/* eslint-disable react-perf/jsx-no-new-function-as-prop */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FormLabel from "components/editorComponents/FormLabel";
import FormRow from "components/editorComponents/FormRow";
import type { ActionResponse, PaginationField } from "api/ActionAPI";
import type { Action, PaginationType } from "entities/Action";
import ApiResponseView from "components/editorComponents/ApiResponseView";
import type { AppState } from "ee/reducers";
import ActionNameEditor from "components/editorComponents/ActionNameEditor";
import { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import { Button, Tab, Tabs, TabsList } from "@appsmith/ads";
import { useParams } from "react-router";
import equal from "fast-deep-equal/es6";
import { getPlugin } from "ee/selectors/entitiesSelector";
import type { AutoGeneratedHeader } from "./helpers";
import { noop } from "lodash";
import { DEFAULT_DATASOURCE_NAME } from "PluginActionEditor/constants/ApiEditorConstants";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import {
  getHasExecuteActionPermission,
  getHasManageActionPermission,
} from "ee/utils/BusinessFeatures/permissionPageHelpers";
import { ApiEditorContext } from "./ApiEditorContext";
import ActionRightPane from "components/editorComponents/ActionRightPane";
import RunHistory from "ee/components/RunHistory";
import { HintMessages } from "PluginActionEditor/components/PluginActionForm/components/CommonEditorForm/HintMessages";
import { InfoFields } from "PluginActionEditor/components/PluginActionForm/components/CommonEditorForm/InfoFields";
import { getSavingStatusForActionName } from "selectors/actionSelectors";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import { ActionUrlIcon } from "../Explorer/ExplorerIcons";
import { ZuoraRequestTabs } from "PluginActionEditor/components/PluginActionForm/components/CommonEditorForm/ZuoraRequestTabs";
import { REST_OR_GRAPHQL_TABS } from "PluginActionEditor/constants/ZuoraApiConstants";
import { initActionPayloadTransformers } from "actions/zuoraActions";

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;

  ${FormLabel} {
    padding: ${(props) => props.theme.spaces[3]}px;
  }

  ${FormRow} {
    align-items: center;

    ${FormLabel} {
      padding: 0;
      width: 100%;
    }
  }

  .api-info-row {
    input {
      margin-left: ${(props) => props.theme.spaces[1] + 1}px;
    }
  }
`;

const MainConfiguration = styled.div`
  z-index: 7;
  padding: 0 var(--ads-v2-spaces-7);

  .api-info-row {
    padding-top: var(--ads-v2-spaces-5);

    .ads-v2-select > .rc-select-selector {
      min-width: 110px;
      width: 110px;
    }
  }

  .form-row-header {
    padding-top: var(--ads-v2-spaces-5);
  }
`;

const ActionButtons = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: var(--ads-v2-spaces-3);
`;

const HelpSection = styled.div`
  padding: var(--ads-v2-spaces-4) var(--ads-v2-spaces-7);
`;

const SecondaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

const TabbedViewContainer = styled.div`
  flex: 1;
  overflow: auto;
  position: relative;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const MainContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  /* padding: var(--ads-v2-spaces-7); */
`;

const TabsListWrapper = styled.div`
  padding: 0 var(--ads-v2-spaces-7);
`;

export interface CommonFormProps {
  actionResponse?: ActionResponse;
  pluginId: string;
  onRunClick: (paginationField?: PaginationField) => void;
  isRunning: boolean;
  isDeleting: boolean;
  paginationType: PaginationType;
  appName: string;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionConfigurationHeaders?: any;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionConfigurationParams?: any;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datasourceHeaders?: any;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  datasourceParams?: any;
  actionName: string;
  apiName: string;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settingsConfig: any;
  hintMessages?: Array<string>;
  autoGeneratedActionConfigHeaders?: AutoGeneratedHeader[];
}

type CommonFormPropsWithExtraParams = CommonFormProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  change(field: string, value: any): void;
  formName: string;
  // Body Tab Component which is passed on from the Parent Component
  bodyUIComponent: JSX.Element;
  // Pagination Tab Component which is passed on from the Parent Component
  paginationUIComponent: JSX.Element;
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  httpsMethods: { value: string }[];
};

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    margin: 0;
    box-sizing: border-box;
  }
`;

const StyledNotificationWrapper = styled.div`
  padding-top: var(--ads-v2-spaces-5);
`;

/**
 * Commons editor form which is being used by API and GraphQL. Since most of the things were common to both so picking out the common part was a better option. For now Body and Pagination component are being passed on by the using component.
 * @param props type CommonFormPropsWithExtraParams
 * @returns Editor with respect to which type is using it
 */
function ZEditorForm(props: CommonFormPropsWithExtraParams) {
  const {
    actionRightPaneAdditionSections,
    moreActionsMenu,
    notification,
    saveActionName,
  } = useContext(ApiEditorContext);

  const {
    actionConfigurationHeaders,
    actionConfigurationParams,
    actionResponse,
    autoGeneratedActionConfigHeaders,
    formName,
    handleSubmit,
    hintMessages,
    isRunning,
    onRunClick,
    pluginId,
    settingsConfig,
  } = props;

  const params = useParams<{ baseApiId?: string; baseQueryId?: string }>();

  // passing lodash's equality function to ensure that this selector does not cause a rerender multiple times.
  // it checks each value to make sure none has changed before recomputing the actions.
  const actions: Action[] = useSelector(
    (state: AppState) => state.entities.actions.map((action) => action.config),
    equal,
  );

  const currentActionConfig: Action | undefined = actions.find(
    (action) =>
      action.baseId === params.baseApiId || action.id === params.baseQueryId,
  );
  const isFeatureEnabled = useFeatureFlag(FEATURE_FLAG.license_gac_enabled);
  const isChangePermitted = getHasManageActionPermission(
    isFeatureEnabled,
    currentActionConfig?.userPermissions,
  );
  const isExecutePermitted = getHasExecuteActionPermission(
    isFeatureEnabled,
    currentActionConfig?.userPermissions,
  );

  const currentPlugin = useSelector((state: AppState) =>
    getPlugin(state, currentActionConfig?.pluginId || ""),
  );

  const saveStatus = useSelector((state) =>
    getSavingStatusForActionName(state, currentActionConfig?.id || ""),
  );

  const iconUrl = getAssetUrl(currentPlugin?.iconLocation) || "";

  const icon = ActionUrlIcon(iconUrl);

  const plugin = useSelector((state: AppState) =>
    getPlugin(state, pluginId ?? ""),
  );

  if (!currentActionConfig) return null;

  // this gets the url of the current action's datasource
  const actionDatasourceUrl =
    currentActionConfig?.datasource?.datasourceConfiguration?.url || "";
  const actionDatasourceUrlPath =
    currentActionConfig?.actionConfiguration?.path || "";
  // this gets the name of the current action's datasource
  const actionDatasourceName = currentActionConfig?.datasource.name || "";

  const actionPreExecuteEnabled =
    currentActionConfig?.actionConfiguration?.preExecute;
  const actionPreExecuteTransform =
    currentActionConfig?.actionConfiguration?.preExecuteTransform;
  const actionPostExecuteEnabled =
    currentActionConfig?.actionConfiguration?.postExecute;
  const actionPostExecuteTransform =
    currentActionConfig?.actionConfiguration?.postExecuteTransform;

  // if the url is empty and the action's datasource name is the default datasource name (this means the api does not have a datasource attached)
  // or the user does not have permission,
  // we block action execution.
  const blockExecution =
    (!actionDatasourceUrl &&
      !actionDatasourceUrlPath &&
      actionDatasourceName === DEFAULT_DATASOURCE_NAME) ||
    !isExecutePermitted;

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      [
        actionPreExecuteEnabled,
        actionPreExecuteTransform,
        actionPostExecuteEnabled,
        actionPostExecuteTransform,
      ].some((item) => item === undefined)
    ) {
      dispatch(
        initActionPayloadTransformers({ id: currentActionConfig?.id || "" }),
      );
    }
  }, [
    actionPostExecuteEnabled,
    actionPostExecuteTransform,
    actionPreExecuteEnabled,
    actionPreExecuteTransform,
    currentActionConfig,
    dispatch,
  ]);

  const theme = EditorTheme.LIGHT;

  const restOrGraphqlTabOnChange = (value: REST_OR_GRAPHQL_TABS) => {
    props.change(
      "actionConfiguration.path",
      value === "GRAPHQL" ? "/object-query/graphql" : "",
    );

    if (value === "GRAPHQL") {
      props.change("actionConfiguration.httpMethod", "POST");
    }

    if (value != restOrGraphql) {
      props.change("actionConfiguration.body", null);
    }

    if (value === "REST") {
      props.change("actionConfiguration.customConfiguration", {});
    }

    setRestOrGraphqlIndex(value);
    setIsZGraphql(value === "GRAPHQL");
  };

  const isZg = actionDatasourceUrlPath === "/object-query/graphql";

  const [isZGraphql, setIsZGraphql] = React.useState(isZg);

  const restOrGraphql =
    actionDatasourceUrlPath === "/object-query/graphql"
      ? REST_OR_GRAPHQL_TABS.GRAPHQL
      : REST_OR_GRAPHQL_TABS.REST;
  const [selectedRestOrGraphql, setRestOrGraphqlIndex] =
    React.useState(restOrGraphql);

  React.useEffect(() => {
    setIsZGraphql(isZg);
    setRestOrGraphqlIndex(restOrGraphql);
  }, [isZg, props.actionName, restOrGraphql]);

  return (
    <MainContainer>
      <Form
        data-testid={`t--action-form-${plugin?.type}`}
        onSubmit={handleSubmit(noop)}
      >
        <Tabs
          className="h-full"
          // eslint-disable-next-line
          //@ts-ignore
          onValueChange={restOrGraphqlTabOnChange}
          value={selectedRestOrGraphql}
        >
          <TabsListWrapper>
            <TabsList>
              {Object.values(REST_OR_GRAPHQL_TABS).map((tab) => (
                <Tab
                  data-testid={`t--api-restorgraphql-${tab}`}
                  key={tab}
                  value={tab}
                >
                  {tab}
                </Tab>
              ))}
            </TabsList>
          </TabsListWrapper>
          <MainConfiguration>
            <FormRow className="form-row-header">
              <NameWrapper className="t--nameOfApi">
                <ActionNameEditor
                  actionConfig={currentActionConfig}
                  disabled={!isChangePermitted}
                  enableFontStyling
                  icon={icon}
                  saveActionName={saveActionName}
                  saveStatus={saveStatus}
                />
              </NameWrapper>
              <ActionButtons className="t--formActionButtons">
                {moreActionsMenu}
                <Button
                  className="t--apiFormRunBtn"
                  isDisabled={blockExecution}
                  isLoading={isRunning}
                  onClick={() => {
                    onRunClick();
                  }}
                  size="md"
                >
                  Run
                </Button>
              </ActionButtons>
            </FormRow>
            {notification && (
              <StyledNotificationWrapper>
                {notification}
              </StyledNotificationWrapper>
            )}
            {!isZGraphql && (
              <FormRow className="api-info-row">
                <InfoFields
                  actionName={props.actionName}
                  changePermitted={isChangePermitted}
                  formName={props.formName}
                  options={props.httpsMethods}
                  pluginId={props.pluginId}
                  theme={EditorTheme.LIGHT}
                />
              </FormRow>
            )}
          </MainConfiguration>
          {!isZGraphql && hintMessages && (
            <HelpSection>
              <HintMessages hintMessages={hintMessages} />
            </HelpSection>
          )}
          <Wrapper>
            <div className="flex flex-1">
              <SecondaryWrapper>
                <TabbedViewContainer>
                  <ZuoraRequestTabs
                    actionConfigurationHeaders={actionConfigurationHeaders}
                    actionConfigurationParams={actionConfigurationParams}
                    actionName={props.actionName}
                    actionPostExecuteEnabled={actionPostExecuteEnabled}
                    actionPostExecuteTransform={actionPostExecuteTransform}
                    actionPreExecuteEnabled={actionPreExecuteEnabled}
                    actionPreExecuteTransform={actionPreExecuteTransform}
                    actionSettingsConfig={settingsConfig}
                    autogeneratedHeaders={autoGeneratedActionConfigHeaders}
                    bodyUIComponent={props.bodyUIComponent}
                    change={props.change}
                    currentActionConfig={currentActionConfig}
                    datasourceHeaders={props.datasourceHeaders}
                    datasourceParams={props.datasourceParams}
                    formName={formName}
                    isChangePermitted={isChangePermitted}
                    isZGraphQL={isZGraphql}
                    paginationUiComponent={props.paginationUIComponent}
                    pushFields={isChangePermitted}
                    showSettings
                    theme={EditorTheme.LIGHT}
                  />
                </TabbedViewContainer>
                <ApiResponseView
                  actionResponse={actionResponse}
                  currentActionConfig={currentActionConfig}
                  isRunDisabled={blockExecution}
                  isRunning={isRunning}
                  onRunClick={onRunClick}
                  theme={theme}
                />
                <RunHistory />
              </SecondaryWrapper>
            </div>
            <ActionRightPane
              additionalSections={actionRightPaneAdditionSections}
            />
          </Wrapper>
        </Tabs>
      </Form>
    </MainContainer>
  );
}

export default ZEditorForm;
