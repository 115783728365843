/* eslint-disable no-console */
import { put, select } from "redux-saga/effects";
import { toast } from "@appsmith/ads";
import log from "loglevel";
import { fetchZuoraEnvironment } from "utils/URLUtils";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import WFActionAPI from "ee/api/WFActionAPI";
import type { WFInstance } from "entities/WFCollection";
import { getWFActionFromWidget } from "ee/selectors/entitiesSelector";
import type { TWFActionProps } from "components/editorComponents/ActionCreator/viewComponents/Action/ActionTree";
import { showZuoraToast } from "ZToast/ZToast.actions";

export default function* runZuoraWorkflow(
  wfDefinitionId: number,
  triggerSourceId: string,
) {
  try {
    let response: WFInstance;
    // no params to be found
    const payload = new FormData();
    const actionConfig: TWFActionProps = yield select(
      getWFActionFromWidget,
      triggerSourceId,
      wfDefinitionId,
    );
    if (actionConfig && actionConfig.parameters.length) {
      actionConfig.parameters.forEach((parameter) =>
        payload.append(parameter.callout_id, parameter.value as string),
      );
      response = yield WFActionAPI.runWFAction(wfDefinitionId, payload);
    } else {
      response = yield WFActionAPI.runWFAction(wfDefinitionId, null);
    }
    console.log(response);
    toast.show("Workflow successfully queued", {
      kind: "success",
      action: {
        text: "View",
        effect: () => {
          const env = fetchZuoraEnvironment();
          let url;
          if (env === "local") {
            url = "http://localhost:3001";
          } else if (env === "stg") {
            url = "/services/workflow-staging";
          } else {
            url = "/services/workflow";
          }
          window.open(
            `${url}/workflows/${response.originalWorkflowId}/executions/${response.id}`,
            "_blank",
          );
        },
      },
    });
    yield put({
      type: ReduxActionTypes.RUN_WF_ACTION_SUCCESS,
      payload: response,
    });
  } catch (e: any) {
    log.error(e);
    for (const err of e.errors) {
      yield put(showZuoraToast(err.title || err.message, { kind: "error" }));
    }
  }
}
