import API from "api/Api";
import type { AxiosPromise } from "axios";
import type { WFDefinition, WFInstance } from "entities/WFCollection";
import type { ApiResponse } from "api/ApiResponses";
import { fetchZuoraEnvironment } from "utils/URLUtils";

class WFActionAPI extends API {
  static currentEnv = fetchZuoraEnvironment(window.location.hostname);
  static isLocalhost = WFActionAPI.currentEnv === "local";
  static baseURL = WFActionAPI.isLocalhost
    ? "http://localhost:3001/api/v1"
    : "/platform/gateway-proxy-v2";

  static url = "/workflows";
  static wfActionAPIConfig = {
    baseURL: WFActionAPI.baseURL,
    headers: WFActionAPI.isLocalhost
      ? {
          "API-Token": "testtest",
          Scope: "Internal",
        }
      : { Scope: "Internal" },
    timeout: 1000 * 60 * 2,
    withCredentials: false,
  };

  static async fetchWFCollections(): Promise<
    AxiosPromise<ApiResponse<WFDefinition[]>>
  > {
    return API.get(
      WFActionAPI.url,
      { appsmith_actions: true },
      WFActionAPI.wfActionAPIConfig,
    );
  }

  static async runWFAction(
    wfDefinitionId: number,
    body: FormData | null,
  ): Promise<AxiosPromise<ApiResponse<WFInstance>>> {
    return API.post(
      `${WFActionAPI.url}/${wfDefinitionId}/run`,
      body,
      null,
      WFActionAPI.wfActionAPIConfig,
    );
  }
}

export default WFActionAPI;
