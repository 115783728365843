import React from "react";
import { SelectItem, Icon } from "zds";
import { MenuItem, Select as MUISelect } from "@mui/material";
import {
  EqualsIcon,
  GreaterThanEqualIcon,
  GreaterThanIcon,
  LessThanEqualIcon,
  LessThanIcon,
  NotEqualsIcon,
} from "./CustomIcons";
import {
  CodeEditorBorder,
  EditorTheme,
} from "../../../../components/editorComponents/CodeEditor/EditorConfig";
import { bindingHintHelper } from "../../../../components/editorComponents/CodeEditor/hintHelpers";
import { slashCommandHintHelper } from "../../../../components/editorComponents/CodeEditor/commandsHelper";
import LazyCodeEditor from "../../../../components/editorComponents/LazyCodeEditor";
import styled from "styled-components";

// Styled components
const ConditionItemContainer = styled.div`
  position: relative;
  display: inline-flex;
  padding: 20px;
  background-color: #f6f7f8;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 505px;
`;

const ArrowIcon = styled.span`
  position: absolute;
  color: rgba(0, 0, 0, 0.24);
  font-size: 10px;
  top: 32px;
  left: -20px;
`;

const ArrowLine = styled.span`
  position: absolute;
  top: 38px;
  left: -71px;
  height: 1px;
  width: 52px;
  background-color: rgba(0, 0, 0, 0.2);
`;

const ConditionFieldSelect = styled.div`
  display: inline-block;
  width: 160px;
  vertical-align: bottom;

  fieldset {
    background-color: #ffffff;
  }
`;

const ConditionTypeSelect = styled.div`
  display: inline-block;
  width: 140px;
  vertical-align: bottom;
  padding-right: 8px;

  fieldset {
    background-color: #ffffff;
  }
`;

const ConditionOperationSelect = styled.div`
  display: inline-block;
  width: 60px;
  margin: 0 8px;

  fieldset {
    background-color: #ffffff;
  }
`;

const ConditionValueSelect = styled.div`
  display: inline-block;
  width: 160px;
  vertical-align: bottom;
  margin: 4px 8px;

  .CodeMirror-focused {
    border: 2px solid #0d4ac3;
    outline: unset !important;
    padding-left: inherit;
    //padding-top: 7px;
  }
`;

const ConditionValueDynamic = styled.div`
  display: inline-block;
  width: 250px;
  vertical-align: bottom;
  margin: 4px 8px;

  .CodeMirror-focused {
    border: 2px solid #0d4ac3;
    outline: unset !important;
    padding-left: inherit;
    //padding-top: 7px;
  }
`;

const ConditionAddDelete = styled.div`
  position: absolute;
  right: -56px;
  top: 26px;
  padding-right: 16px;

  .delete {
    cursor: pointer;
    display: inline-block;
  }
`;

export const ConditionItem = (props) => {
  const conditionOperatorDisplay = (selected) => {
    switch (selected) {
      case "EQ":
        return <EqualsIcon />;
      case "NE":
        return <NotEqualsIcon />;
      case "LT":
        return <LessThanIcon />;
      case "LE":
        return <LessThanEqualIcon />;
      case "GT":
        return <GreaterThanIcon />;
      case "GE":
        return <GreaterThanEqualIcon />;
      case "SW":
        return "SW";
      default:
        return null;
    }
  };

  return (
    <ConditionItemContainer>
      <ArrowIcon>▶</ArrowIcon>
      <ArrowLine />
      <ConditionTypeSelect title={props.conditionData.name}>
        <MUISelect
          fullWidth
          onChange={(e) => {
            props.updateCondition(
              props.index,
              "update",
              "type",
              e.target.value,
            );
          }}
          sx={{ height: "40px" }}
          value={props.conditionData.type || "static"}
        >
          <SelectItem key={`static`} value={"static"}>
            Static
          </SelectItem>
          <SelectItem key={`dynamic`} value={"dynamic"}>
            Dynamic
          </SelectItem>
        </MUISelect>
      </ConditionTypeSelect>
      {props.conditionData.type === "dynamic" ? (
        <ConditionValueDynamic>
          <LazyCodeEditor
            {...props}
            border={CodeEditorBorder.ALL_SIDE}
            className="t--datasource-editor"
            hinting={[bindingHintHelper, slashCommandHintHelper]}
            input={{
              value: props.conditionData.value,
              onChange: (value) => {
                props.updateCondition(props.index, "update", "value", value);
              },
            }}
            theme={props.theme || EditorTheme.LIGHT}
          />
        </ConditionValueDynamic>
      ) : (
        <>
          <ConditionFieldSelect title={props.conditionData.name}>
            <MUISelect
              fullWidth
              onChange={(e) => {
                props.updateCondition(
                  props.index,
                  "update",
                  "field",
                  e.target.value,
                );
              }}
              sx={{ height: "40px" }}
              value={props.conditionData.field}
            >
              {props.objectFieldSelectProps &&
                props.objectFieldSelectProps.map((field, index) => {
                  return (
                    <SelectItem
                      key={`condition-field-${index}`}
                      value={field.value}
                    >
                      {field.name}
                    </SelectItem>
                  );
                })}
            </MUISelect>
          </ConditionFieldSelect>
          <ConditionOperationSelect>
            <MUISelect
              onChange={(e) => {
                props.updateCondition(
                  props.index,
                  "update",
                  "operator",
                  e.target.value,
                );
              }}
              renderValue={(selected) => conditionOperatorDisplay(selected)}
              sx={{ height: "40px" }}
              value={props.conditionData.operator}
            >
              <MenuItem sx={{ paddingLeft: "20px" }} value="EQ">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  equals
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="NE">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  does not equal
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="LT">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  is less than
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="LE">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  is less than or equal to
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="GT">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  is greater than
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="GE">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  is greater than or equal to
                </div>
              </MenuItem>
              <MenuItem sx={{ paddingLeft: "20px" }} value="SW">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "6px" }}
                >
                  starts with
                </div>
              </MenuItem>
            </MUISelect>
          </ConditionOperationSelect>
          <ConditionValueSelect>
            <LazyCodeEditor
              {...props}
              border={CodeEditorBorder.ALL_SIDE}
              className="t--datasource-editor"
              hinting={[bindingHintHelper, slashCommandHintHelper]}
              input={{
                value: props.conditionData.value,
                onChange: (value) => {
                  props.updateCondition(props.index, "update", "value", value);
                },
              }}
              theme={props.theme || EditorTheme.LIGHT}
            />
          </ConditionValueSelect>
        </>
      )}
      <ConditionAddDelete>
        <div
          className="delete"
          onClick={() => {
            props.updateCondition(props.index, "delete");
          }}
        >
          <Icon body="delete_outline" />
        </div>
      </ConditionAddDelete>
    </ConditionItemContainer>
  );
};
