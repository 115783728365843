import { call } from "redux-saga/effects";
import AppsmithConsole from "utils/AppsmithConsole";
import type { TRunZuoraWorkflowDescription } from "workers/Evaluation/fns/runZuoraWorkflow";
import runZuoraWorkflow from "sagas/ZuoraWorkflowSagas";

export default function* runZuoraWorkflowSaga(
  wfAction: TRunZuoraWorkflowDescription,
  triggerSourceId: string,
) {
  //   console.log(wfAction);
  const { payload } = wfAction;
  // TODO: add parameter validation here
  yield call(
    runZuoraWorkflow,
    parseInt(payload.wfDefinitionId),
    triggerSourceId as string,
  );
  AppsmithConsole.info({
    text: `runZuoraWorkflow(${payload.wfDefinitionId}) was triggered by widget ${triggerSourceId}`,
  });
}
