/* eslint-disable @typescript-eslint/no-restricted-imports */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Text, Button, Input, Icon } from "@appsmith/ads"; // Assuming these components are available in the design system
import {
  UPDATE_GLOBAL_ZUORA_ENVIRONMENT_VARS,
  createMessage,
} from "ee/constants/messages";
import { getCurrentZEnvVars } from "selectors/editorSelectors";
import { setZCustomVars } from "ce/actions/zuoraActions";

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const KeyValueRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const DeleteButton = styled(Button)`
  color: red;
`;

interface KeyValue {
  key: string;
  value: string;
}

export function EnvironmentVarsSettings() {
  const dispatch = useDispatch();
  const zCustomVars = useSelector(getCurrentZEnvVars) || {};
  const initialVars = Object.entries(zCustomVars).map(([key, value]) => ({
    key,
    value: value as unknown as string,
  }));
  const [envVars, setEnvVars] = useState<KeyValue[]>(
    initialVars.length > 0 ? initialVars : [{ key: "", value: "" }],
  );

  const handleKeyChange = (index: number, value: string) => {
    const newEnvVars = [...envVars];
    newEnvVars[index].key = value;
    setEnvVars(newEnvVars);
  };

  const handleValueChange = (index: number, value: string) => {
    const newEnvVars = [...envVars];
    newEnvVars[index].value = value;
    setEnvVars(newEnvVars);
  };

  const addNewField = () => {
    const lastPair = envVars[envVars.length - 1];
    if (lastPair && lastPair.key && lastPair.value) {
      setEnvVars([...envVars, { key: "", value: "" }]);
    } else {
      alert("Please fill in both key and value before adding a new field.");
    }
  };

  const deleteField = (index: number) => {
    const newEnvVars = envVars.filter((_, i) => i !== index);
    setEnvVars(newEnvVars);
  };

  const saveFields = () => {
    dispatch(setZCustomVars(envVars));
  };

  return (
    <SettingWrapper>
      <Text kind="heading-s">
        {createMessage(UPDATE_GLOBAL_ZUORA_ENVIRONMENT_VARS.settingHeader)}
      </Text>
      <ContentWrapper>
        {envVars.map((pair, index) => (
          <KeyValueRow key={index}>
            <Input
              onChange={(value) => handleKeyChange(index, value)}
              placeholder="Key"
              value={pair.key}
            />
            <Input
              onChange={(value) => handleValueChange(index, value)}
              placeholder="Value"
              value={pair.value}
            />
            <DeleteButton kind="secondary" onClick={() => deleteField(index)}>
              <Icon name="delete" />
            </DeleteButton>
          </KeyValueRow>
        ))}
        <ButtonWrapper>
          <Button kind="primary" onClick={addNewField}>
            Add New
          </Button>
          <Button kind="secondary" onClick={saveFields}>
            Save
          </Button>
        </ButtonWrapper>
      </ContentWrapper>
    </SettingWrapper>
  );
}
