export const SNAPSHOT_EXPIRY_IN_DAYS = 5;

export const defaultAutoLayoutWidgets = [
  "CONTAINER_WIDGET",
  "TABS_WIDGET",
  "ZTABS_WIDGET",
  "STEPPER_WIDGET",
  "LIST_WIDGET_V2",
  "MODAL_WIDGET",
  "ZMODAL_WIDGET",
  "STATBOX_WIDGET",
  "FORM_WIDGET",
];
