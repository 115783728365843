import { changeAppViewAccessInit } from "ee/actions/applicationActions";
import { Switch, Divider } from "@appsmith/ads";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentApplication,
  getIsChangingAutoInstall,
  getIsChangingViewAccess,
  getIsChangingZTemplate,
} from "ee/selectors/applicationSelectors";
import PropertyHelpLabel from "pages/Editor/PropertyPane/PropertyHelpLabel";
import styled from "styled-components";
import {
  createMessage,
  IN_APP_EMBED_SETTING,
  MAKE_APPLICATION_PUBLIC_TOOLTIP,
  MAKE_APPLICATION_PUBLIC,
  MAKE_AUTOINSTALL,
  MAKE_AUTOINSTALL_TOOLTIP,
  MAKE_ZTEMPLATE,
  MAKE_ZTEMPLATE_TOOLTIP,
  GENERAL_SETTINGS_ZSTORE_INVALID_MESSAGE,
  GENERAL_SETTINGS_ZSTORE_NAME_LABEL,
} from "ee/constants/messages";
import { isPermitted, PERMISSION_TYPE } from "ee/utils/permissionHelpers";
import MakeApplicationForkable from "./MakeApplicationForkable";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getEmbedSnippetTab } from "ee/utils/BusinessFeatures/privateEmbedHelpers";
import { getIsFetchingApplications } from "ee/selectors/selectedWorkspaceSelectors";
import {
  changeAppZStoreName,
  changeAppAutoInstall,
  changeAppZTemplate,
} from "ee/actions/zuoraActions";
import { Input } from "@appsmith/ads";

const StyledPropertyHelpLabel = styled(PropertyHelpLabel)`
  .bp3-popover-content > div {
    text-align: center;
    max-height: 44px;
    display: flex;
    align-items: center;
  }
`;

const Title = styled.p`
  font-size: var(--ads-v2-font-size-4);
  line-height: 1.2rem;
  font-weight: var(--ads-v2-font-weight-bold);
  color: var(--ads-v2-color-fg-emphasis);
`;

function EmbedSettings() {
  const application = useSelector(getCurrentApplication);
  const dispatch = useDispatch();
  const isChangingViewAccess = useSelector(getIsChangingViewAccess);
  const isFetchingApplication = useSelector(getIsFetchingApplications);
  const userAppPermissions = application?.userPermissions ?? [];
  const isChangingZTemplate = useSelector(getIsChangingZTemplate);
  const isChangingAutoInstall = useSelector(getIsChangingAutoInstall);
  const [isZStoreNameValid] = useState(true);
  const [zStoreName, setZStoreName] = useState(application?.zstorename);
  const isPrivateEmbedEnabled = useFeatureFlag(
    FEATURE_FLAG.license_private_embeds_enabled,
  );
  const canShareWithPublic = isPermitted(
    userAppPermissions,
    PERMISSION_TYPE.MAKE_PUBLIC_APPLICATION,
  );
  const canMarkAppForkable = isPermitted(
    userAppPermissions,
    PERMISSION_TYPE.EXPORT_APPLICATION,
  );

  return (
    <div>
      {canShareWithPublic && (
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>
              {createMessage(IN_APP_EMBED_SETTING.sectionContentHeader)}
            </Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={isFetchingApplication || isChangingViewAccess}
                isSelected={application?.isPublic}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_APPLICATION_PUBLIC", {
                    isPublic: !application?.isPublic,
                  });
                  application &&
                    dispatch(
                      changeAppViewAccessInit(
                        application?.id,
                        !application?.isPublic,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(MAKE_APPLICATION_PUBLIC)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(MAKE_APPLICATION_PUBLIC_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <Divider />
        </>
      )}
      {application?.isPublic && (
        <>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Input
                defaultValue={zStoreName}
                errorMessage={
                  isZStoreNameValid
                    ? undefined
                    : GENERAL_SETTINGS_ZSTORE_INVALID_MESSAGE()
                }
                // undefined sent implicitly - parameter "icon"
                id="t--general-settings-app-name"
                isValid={isZStoreNameValid}
                label={GENERAL_SETTINGS_ZSTORE_NAME_LABEL()}
                onBlur={() => {
                  application &&
                    dispatch(changeAppZStoreName(application?.id, zStoreName));
                }}
                onChange={(value: string) => {
                  setZStoreName(value);
                }}
                onKeyPress={(ev: React.KeyboardEvent) => {
                  if (ev.key === "Enter") {
                    // undefined sent implicitly - parameter "icon"
                    application &&
                      dispatch(
                        changeAppZStoreName(application?.id, zStoreName),
                      );
                  }
                }}
                placeholder="Store Name"
                size="md"
                type="text"
                value={zStoreName}
              />
            </div>
          </div>
          <Divider />
        </>
      )}
      {
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>
              {createMessage(IN_APP_EMBED_SETTING.templateContentHeader)}
            </Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={
                  isFetchingApplication ||
                  isChangingZTemplate ||
                  isChangingAutoInstall
                }
                isSelected={application?.isZTemplate}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_ZTEMPLATE", {
                    isZTemplate: !application?.isZTemplate,
                  });
                  application &&
                    dispatch(
                      changeAppZTemplate(
                        application?.id,
                        !application?.isZTemplate,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(MAKE_ZTEMPLATE)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(MAKE_ZTEMPLATE_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <Divider />
        </>
      }
      {
        <>
          <div className="px-4 mt-4 mb-3">
            <Title>
              {createMessage(IN_APP_EMBED_SETTING.autoInstallContentHeader)}
            </Title>
          </div>
          <div className="px-4">
            <div className="flex justify-between content-center">
              <Switch
                data-testid="t--embed-settings-application-public"
                isDisabled={
                  isFetchingApplication ||
                  isChangingZTemplate ||
                  isChangingAutoInstall
                }
                isSelected={application?.isAutoInstall}
                onChange={() => {
                  AnalyticsUtil.logEvent("MAKE_AUTOINSTALL", {
                    isAutoInstall: !application?.isAutoInstall,
                  });
                  application &&
                    dispatch(
                      changeAppAutoInstall(
                        application?.id,
                        !application?.isAutoInstall,
                      ),
                    );
                }}
              >
                <StyledPropertyHelpLabel
                  label={createMessage(MAKE_AUTOINSTALL)}
                  lineHeight="1.17"
                  maxWidth="270px"
                  tooltip={createMessage(MAKE_AUTOINSTALL_TOOLTIP)}
                />
              </Switch>
            </div>
          </div>
          <Divider />
        </>
      }
      {canMarkAppForkable && (
        <MakeApplicationForkable application={application} />
      )}
      {getEmbedSnippetTab(isPrivateEmbedEnabled)}
    </div>
  );
}

export default EmbedSettings;
