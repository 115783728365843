export default {
  ZUORA_SET_COMPONENT_MANAGER_IS_LOADED:
    "ZUORA_SET_COMPONENT_MANAGER_IS_LOADED",

  // Object Components

  ZUORA_RESET_OBJECT_COMPONENT_SAVE_STATUS:
    "ZUORA_RESET_OBJECT_COMPONENT_SAVE_STATUS",
  ZUORA_RESET_OBJECT_COMPONENT_LOAD_STATUS:
    "ZUORA_RESET_OBJECT_COMPONENT_LOAD_STATUS",

  ZUORA_SAVE_OBJECT_COMPONENTS_INIT: "ZUORA_SAVE_OBJECT_COMPONENTS_INIT",
  ZUORA_SAVE_OBJECT_COMPONENTS_SUCCESS: "ZUORA_SAVE_OBJECT_COMPONENTS_SUCCESS",
  ZUORA_SAVE_OBJECT_COMPONENTS_FAILURE: "ZUORA_SAVE_OBJECT_COMPONENTS_FAILURE",

  ZUORA_LOAD_OBJECT_COMPONENTS_INIT: "ZUORA_LOAD_OBJECT_COMPONENTS_INIT",
  ZUORA_LOAD_OBJECT_COMPONENTS_SUCCESS: "ZUORA_LOAD_OBJECT_COMPONENTS_SUCCESS",
  ZUORA_LOAD_OBJECT_COMPONENTS_FAILURE: "ZUORA_LOAD_OBJECT_COMPONENTS_FAILURE",

  // Custom Fields

  ZUORA_RESET_CUSTOM_FIELD_SAVE_STATUS: "ZUORA_RESET_CUSTOM_FIELD_SAVE_STATUS",
  ZUORA_RESET_CUSTOM_FIELD_LOAD_STATUS: "ZUORA_RESET_CUSTOM_FIELD_LOAD_STATUS",

  ZUORA_SAVE_CUSTOM_FIELDS_INIT: "ZUORA_SAVE_CUSTOM_FIELDS_INIT",
  ZUORA_SAVE_CUSTOM_FIELDS_SUCCESS: "ZUORA_SAVE_CUSTOM_FIELDS_SUCCESS",
  ZUORA_SAVE_CUSTOM_FIELDS_FAILURE: "ZUORA_SAVE_CUSTOM_FIELDS_FAILURE",

  ZUORA_LOAD_CUSTOM_FIELDS_INIT: "ZUORA_LOAD_CUSTOM_FIELDS_INIT",
  ZUORA_LOAD_CUSTOM_FIELDS_SUCCESS: "ZUORA_LOAD_CUSTOM_FIELDS_SUCCESS",
  ZUORA_LOAD_CUSTOM_FIELDS_FAILURE: "ZUORA_LOAD_CUSTOM_FIELDS_FAILURE",

  // Custom Events

  ZUORA_RESET_CUSTOM_EVENT_SAVE_STATUS: "ZUORA_RESET_CUSTOM_EVENT_SAVE_STATUS",
  ZUORA_RESET_CUSTOM_EVENT_LOAD_STATUS: "ZUORA_RESET_CUSTOM_EVENT_LOAD_STATUS",

  ZUORA_SAVE_CUSTOM_EVENTS_INIT: "ZUORA_SAVE_CUSTOM_EVENTS_INIT",
  ZUORA_SAVE_CUSTOM_EVENTS_SUCCESS: "ZUORA_SAVE_CUSTOM_EVENTS_SUCCESS",
  ZUORA_SAVE_CUSTOM_EVENTS_FAILURE: "ZUORA_SAVE_CUSTOM_EVENTS_FAILURE",

  ZUORA_LOAD_CUSTOM_EVENTS_INIT: "ZUORA_LOAD_CUSTOM_EVENTS_INIT",
  ZUORA_LOAD_CUSTOM_EVENTS_SUCCESS: "ZUORA_LOAD_CUSTOM_EVENTS_SUCCESS",
  ZUORA_LOAD_CUSTOM_EVENTS_FAILURE: "ZUORA_LOAD_CUSTOM_EVENTS_FAILURE",

  // Workflows

  ZUORA_RESET_WORKFLOW_SAVE_STATUS: "ZUORA_RESET_WORKFLOW_SAVE_STATUS",
  ZUORA_RESET_WORKFLOW_LOAD_STATUS: "ZUORA_RESET_WORKFLOW_LOAD_STATUS",

  ZUORA_SAVE_WORKFLOWS_INIT: "ZUORA_SAVE_WORKFLOWS_INIT",
  ZUORA_SAVE_WORKFLOWS_SUCCESS: "ZUORA_SAVE_WORKFLOWS_SUCCESS",
  ZUORA_SAVE_WORKFLOWS_FAILURE: "ZUORA_SAVE_WORKFLOWS_FAILURE",

  ZUORA_LOAD_WORKFLOWS_INIT: "ZUORA_LOAD_WORKFLOWS_INIT",
  ZUORA_LOAD_WORKFLOWS_SUCCESS: "ZUORA_LOAD_WORKFLOWS_SUCCESS",
  ZUORA_LOAD_WORKFLOWS_FAILURE: "ZUORA_LOAD_WORKFLOWS_FAILURE",

  // SSO
  CHANGE_APP_SSO_LOGIN_INIT: "CHANGE_APP_SSO_LOGIN_INIT",
  CHANGE_APP_SSO_LOGIN_SUCCESS: "CHANGE_APP_SSO_LOGIN_SUCCESS",
  CHANGE_APP_SSO_CUSTOM_PAGE_INIT: "CHANGE_APP_SSO_CUSTOM_PAGE_INIT",
  CHANGE_APP_SSO_CUSTOM_PAGE_SUCCESS: "CHANGE_APP_SSO_CUSTOM_PAGE_SUCCESS",
  CHANGE_APP_SSO_LOGIN_TYPES_INIT: "CHANGE_APP_SSO_LOGIN_TYPES_INIT",
  CHANGE_APP_SSO_LOGIN_TYPES_SUCCESS: "CHANGE_APP_SSO_LOGIN_TYPES_SUCCESS",
  CHANGE_APP_SSO_APPROVED_DOMAINS_INIT: "CHANGE_APP_SSO_APPROVED_DOMAINS_INIT",
  CHANGE_APP_SSO_APPROVED_DOMAINS_SUCCESS:
    "CHANGE_APP_SSO_APPROVED_DOMAINS_SUCCESS",
  CHANGE_APP_SSO_APPROVED_USERS_INIT: "CHANGE_APP_SSO_APPROVED_USERS_INIT",
  CHANGE_APP_SSO_APPROVED_USERS_SUCCESS:
    "CHANGE_APP_SSO_APPROVED_USERS_SUCCESS",
  CHANGE_APP_SSO_UNAUTHED_PAGES_INIT: "CHANGE_APP_SSO_UNAUTHED_PAGES_INIT",
  CHANGE_APP_SSO_UNAUTHED_PAGES_SUCCESS:
    "CHANGE_APP_SSO_UNAUTHED_PAGES_SUCCESS",
  CHANGE_APP_SSO_LANDING_PAGE_INIT: "CHANGE_APP_SSO_LANDING_PAGE_INIT",
  CHANGE_APP_SSO_LANDING_PAGE_SUCCESS: "CHANGE_APP_SSO_LANDING_PAGE_SUCCESS",

  // some other
  SET_ZUORA_SESSION_DATA_INIT: "SET_ZUORA_SESSION_DATA_INIT",
  SET_ZUORA_SESSION_DATA_SUCCESS: "SET_ZUORA_SESSION_DATA_SUCCESS",
  FETCH_ZCONSTANTS_INIT: "FETCH_ZCONSTANTS_INIT",
  FETCH_ZCONSTANTS_SUCCESS: "FETCH_ZCONSTANTS_SUCCESS",
  FETCH_ZENVCONSTANTS_INIT: "FETCH_ZENVCONSTANTS_INIT",
  FETCH_ZUORA_GENESIS_INFO_INIT: "FETCH_ZUORA_GENESIS_INFO_INIT",
  FETCH_ZUORA_GENESIS_INFO_SUCCESS: "FETCH_ZUORA_GENESIS_INFO_SUCCESS",
  FETCH_ZENVCONSTANTS_SUCCESS: "FETCH_ZENVCONSTANTS_SUCCESS",
  CHANGE_APP_ZUO_NAV_ERROR: "CHANGE_APP_ZUO_NAV_ERROR",
  CHANGE_APP_ZTEMPLATE_ERROR: "CHANGE_APP_ZTEMPLATE_ERROR",
  CHANGE_APP_ZSTORE_NAME_ERROR: "CHANGE_APP_ZSTORE_NAME_ERROR",
  FETCH_WF_ACTIONS_ERROR: "FETCH_WF_ACTIONS_ERROR",
  UPDATE_POSITIONS_ON_STEP_CHANGE: "UPDATE_POSITIONS_ON_STEP_CHANGE",
  WIDGET_ADD_NEW_STEP_CHILD: "WIDGET_ADD_NEW_STEP_CHILD",
  WIDGET_DELETE_STEP_CHILD: "WIDGET_DELETE_STEP_CHILD",
  CHANGE_APP_ZUO_NAV_INIT: "CHANGE_APP_ZUO_NAV_INIT",
  CHANGE_APP_ZUO_NAV_SUCCESS: "CHANGE_APP_ZUO_NAV_SUCCESS",
  CHANGE_APP_ZUO_NAV_OPTION_INIT: "CHANGE_APP_ZUO_NAV_OPTION_INIT",
  CHANGE_APP_ZUO_NAV_OPTION_SUCCESS: "CHANGE_APP_ZUO_NAV_OPTION_SUCCESS",
  CHANGE_APP_AUTOINSTALL_INIT: "CHANGE_APP_AUTOINSTALL_INIT",
  CHANGE_APP_AUTOINSTALL_SUCCESS: "CHANGE_APP_AUTOINSTALL_SUCCESS",
  CHANGE_APP_ZTEMPLATE_INIT: "CHANGE_APP_ZTEMPLATE_INIT",
  CHANGE_APP_ZTEMPLATE_SUCCESS: "CHANGE_APP_ZTEMPLATE_SUCCESS",
  CHANGE_APP_ZSTORE_NAME_INIT: "CHANGE_APP_ZSTORE_NAME_INIT",
  CHANGE_APP_ZSTORE_NAME_SUCCESS: "CHANGE_APP_ZSTORE_NAME_SUCCESS",
  CHANGE_APP_ZCONSTANTS_INIT: "CHANGE_APP_ZCONSTANTS_INIT",
  CHANGE_APP_ZCONSTANTS_SUCCESS: "CHANGE_APP_ZCONSTANTS_SUCCESS",
  CHANGE_APP_ZCUSTOMVARS_INIT: "CHANGE_APP_ZCUSTOMVARS_INIT",
  CHANGE_APP_ZCUSTOMVARS_SUCCESS: "CHANGE_APP_ZCUSTOMVARS_SUCCESS",
  GET_ZTEMPLATE_INIT: "GET_ZTEMPLATES_INIT",
  GET_ZTEMPLATE_SUCCESS: "GET_ZTEMPLATES_SUCCESS",
  GET_ALL_ZTEMPLATES_INIT: "GET_ALL_ZTEMPLATES_INIT",
  GET_ALL_ZTEMPLATES_SUCCESS: "GET_ALL_ZTEMPLATES_SUCCESS",
  FETCH_WF_ACTIONS_INIT: "FETCH_WF_ACTIONS_INIT",
  FETCH_WF_ACTIONS_SUCCESS: "FETCH_WF_ACTIONS_SUCCESS",
  RUN_WF_ACTION_SUCCESS: "RUN_WF_ACTION_SUCCESS",
  CREATE_MISSING_TRANSFORMERS_INIT: "CREATE_MISSING_TRANSFORMERS_INIT",
  IMPORT_ZTEMPLATE_TO_WORKSPACE_INIT: "IMPORT_ZTEMPLATE_TO_WORKSPACE_INIT",
  IMPORT_ZTEMPLATE_TO_WORKSPACE_SUCCESS:
    "IMPORT_ZTEMPLATE_TO_WORKSPACE_SUCCESS",
  ZLOGOUT_USER_INIT: "ZLOGOUT_USER_INIT",
  WIDGET_ADD_NEW_ACCORDION_CHILD: "WIDGET_ADD_NEW_ACCORDION_CHILD",
};
