import { promisify } from "./utils/Promisify";
import type { WFActionEntity } from "ee/entities/DataTree/types";

function runZuoraWorkflowFnDescriptor(this: WFActionEntity) {
  return {
    type: "RUN_ZUORA_WORKFLOW" as const,
    payload: {
      wfDefinitionId: this.actionId,
      config: this.config,
    },
  };
}

export type TRunZuoraWorkflowArgs = Parameters<
  typeof runZuoraWorkflowFnDescriptor
>;
export type TRunZuoraWorkflowDescription = ReturnType<
  typeof runZuoraWorkflowFnDescriptor
>;
export type TRunZuoraWorkflowActionType = TRunZuoraWorkflowDescription["type"];

async function runZuoraWorkflow(this: WFActionEntity) {
  const executor = promisify(runZuoraWorkflowFnDescriptor.bind(this));
  try {
    const response = await executor();
    return response;
  } catch (e) {
    throw e;
  }
}

export default runZuoraWorkflow;
